import React, { useEffect, useState } from 'react'
import { pageDetailApiService } from '../../services/apiService'
import { Container } from 'react-bootstrap'

const BookServices = () => {
    const [pageData, setPageData] = useState({})
    const getPageData = () => {
        const body = {
            pageTitle: 'CONTACT US'
        }
        pageDetailApiService.getPageDetails(body).then((response) => {
            if (response.data.status == true) {
                const responseData = response.data.data.pageData
                setPageData(responseData)
            }
        })
    }

    useEffect(() => {
        getPageData()
    }, [])

    useEffect(() => {
        // Load the iframe-resizer library dynamically
        const script = document.createElement('script');
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js";
        script.onload = () => {
          // Initialize the iFrameResizer if available
          if (window.iFrameResize) {
            window.iFrameResize({
              log: false,
              checkOrigin: false,
              tolerance: 10,
              sizeHeight: true,
              heightCalculationMethod: 'lowestElement',
              minHeight: 300,
              scrolling: 'auto'
            }, '#glofox_6');
          }
        };
        document.body.appendChild(script);
    
        // Cleanup the script on component unmount
        return () => {
          document.body.removeChild(script);
        };
      }, []);

    return (
        <div className='about-container'>

            <div className='inner-hero'>
                <div className='inner-hero-image'>
                    <img src={pageData?.pilotBlock?.image} alt='' />
                </div>
                <div className='herotext wow fadeInUp'>
                    <Container>
                        <h1>Book Packages and Memberships</h1>

                    </Container>
                </div>
            </div>

            <div style={{ textAlign: 'center' }}>
      <iframe
        id="glofox_6"
        src="https://app.glofox.com/portal/#/branch/66eaca5e7794eba08f074a54/classes-week-view"
        width="100%"
        height="0"
        scrolling="no"
        frameBorder="0"
        title="Glofox Classes"
      ></iframe>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          textDecoration: 'none',
          fontFamily: 'Arial, Helvetica, sans-serif',
        }}
      >
        powered by
        <a
          href="https://www.glofox.com"
          style={{ textDecoration: 'none', fontFamily: 'Arial, Helvetica, sans-serif' }}
        >
          <b>&nbsp;Glofox</b>
        </a>
      </div> */}
    </div>


        </div>
    )
}

export default BookServices